<template>
  <div class="ticket">
    <KTCodePreview v-bind:title="'Danh sách cấu hình loại phiếu'">
      <template v-slot:toolbar v-if="checkPermissions(['TICKET_SETTING'])">
        <div class="row">
          <b-button size="sm" variant="primary" @click="createItem()">
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>
            <b>Thêm mới</b>
          </b-button>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0" md="3">
                <b-input
                  v-model="id"
                  placeholder="Nhập ID phiếu"
                  size="sm"
                ></b-input>
              </b-col>
              <b-col class="pl-0" md="3">
                <b-input
                  v-model="search"
                  placeholder="Nhập tên phiếu"
                  size="sm"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchListTypeTicket()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="12">
            <b-table :items="items" :fields="fields" bordered>
              <template v-slot:cell(createdAt)="row">
                <span class="sumary-text text-center">
                  {{ formatDate(row.item.createdAt) }}
                </span>
              </template>

              <template v-slot:cell(status)="row">
                <div class="text-center">
                  <span
                    style="width: max-content"
                    v-bind:class="{
                      'badge badge-success':
                        row.item.status === TICKET_TYPE_STATUS.ON,
                      'badge badge-danger':
                        row.item.status === TICKET_TYPE_STATUS.OFF,
                    }"
                  >
                    {{ TICKET_TYPE_STATUS_NAME[row.item.status] }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item
                    @click="detailItem(row.item)"
                    v-if="checkPermissions(['TICKET_SETTING'])"
                  >
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen icon-color"
                    ></i>
                    &nbsp; Chi tiết
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="paging">
          <div class="total">
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </div>
          <div class="paging-nav">
            <b-pagination-nav
              class="custom-pagination"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchListTypeTicket"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </div>
        </div>
        <b-col></b-col>
        <b-col></b-col>

        <!-- Cấu hình chức vụ - số giờ công -->
        <div class="add-model">
          <b-modal ref="my-modal" hide-footer :title="titleModal">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Tên phiếu hỗ trợ</label>
                  <b-form-input
                    size="sm"
                    placeholder="Nhập tên phiếu hỗ trợ"
                    v-model="ticketType.name"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Bot group thông báo</label>
                  <treeselect
                    :options="filteredBot"
                    :multiple="false"
                    placeholder="Chọn bot"
                    noResultsText="Không có kết quả"
                    :match-keys="['label']"
                    openDirection="bottom"
                    :clearable="false"
                    v-model="ticketType.bot_id"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Nhóm quyền</label>
                  <treeselect
                    :options="filterRole"
                    :multiple="true"
                    placeholder="Chọn nhóm quyền"
                    noResultsText="Không có kết quả"
                    :match-keys="['label']"
                    openDirection="bottom"
                    :clearable="false"
                    v-model="ticketType.roles_id"
                    @select="fetchUser"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Chọn tài khoản thông báo</label>
                  <treeselect
                    :options="listUser"
                    :multiple="true"
                    placeholder="Chọn nhóm quyền"
                    noResultsText="Không có kết quả"
                    :match-keys="['label']"
                    openDirection="bottom"
                    :clearable="false"
                    v-model="selectedUserInGroup"
                    :valueFormat="'object'"
                    @deselect="removeItemUserInGroup"
                    @select="pushItemUserInGroup"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label">{{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              v-for="(item, index) in ticketType.userInGroupTele"
              :key="index"
            >
              <b-col>
                <b-form-group>
                  <label
                    >Nhập tên hoặc ID telegram nhân viên:
                    {{ item.label }}</label
                  >
                  <b-form-input
                    size="sm"
                    placeholder="Nhập tê hoặc ID telegram nhân viên"
                    v-model="
                      ticketType.userInGroupTele[index]['userIdOrNameTele']
                    "
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <!-- todo -->
                <b-form-group>
                  <label>Chọn chức năng thực hiện khi duyệt phiếu</label>
                  <treeselect
                    :options="listFunctionAction"
                    :multiple="false"
                    placeholder="Chọn chức năng"
                    noResultsText="Không có kết quả"
                    :match-keys="['label']"
                    openDirection="bottom"
                    :clearable="false"
                    v-model="ticketType.functionAction"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="actionsUpSert === 'update'">
              <b-col>
                <b-form-group>
                  <label>Trạng thái</label>
                  <b-form-select
                    class="select-style"
                    size="sm"
                    v-model="ticketType.status"
                    :options="listStatusTicket"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              class="mt-2"
              variant="btn btn-primary"
              block
              @click="onSaveModal"
              >Lưu</b-button
            >

            <b-button
              class="mt-3"
              variant="btn btn-outline-secondary"
              block
              @click="hideModal"
              >Đóng</b-button
            >
          </b-modal>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.paging {
  display: flex;
  justify-content: space-between;
}
.form-group label {
  font-weight: 500;
}
.submit-config button {
  font-weight: 600;
  width: 70px;
}
.submit-config button:nth-child(2) {
  margin-left: 10px;
}
.transport-config-header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
}
.sumary-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ticket /deep/ .vue-treeselect__control {
  border-radius: 0.28rem;
  border: 1px solid #ced4da;
  height: 2.5rem;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import { TICKET_STATUS_NAME, TICKET_TYPE_STATUS_NAME } from '@/utils/enum-name';
import { TICKET_STATUS, TICKET_TYPE_STATUS } from '@/utils/enum';
import { formatDate } from '@/utils/common';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { some } from 'lodash';

export default {
  components: {
    KTCodePreview,
    Treeselect,
  },
  data() {
    return {
      formatDate,
      TICKET_STATUS_NAME,
      TICKET_STATUS,
      TICKET_TYPE_STATUS,
      TICKET_TYPE_STATUS_NAME,
      statusOptions: [
        { id: -1, label: 'Chọn trạng thái' },
        { id: 1, label: 'Ghi nhận thông tin' },
        { id: 2, label: 'Đang xử lí' },
        { id: 3, label: 'Hoàn thành' },
        { id: 4, label: 'Từ chối' },
      ],
      listFunctionAction: [{ id: '', label: 'Chọn chức năng' }],
      stores: [],
      storeOptions: [],
      totalPages: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      items: [],
      id: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên cấu hình',
          thStyle: { color: '#181c32', width: '240px' },
        },
        {
          key: 'code',
          label: 'Mã cấu hình',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { color: '#181c32', width: '120px', textAlign: 'center' },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      selectedStatus: -1,
      listStatus: [
        { id: -1, name: 'Chọn trạng thái' },
        { id: 0, name: 'Mới' },
        { id: 1, name: 'Ghi nhận thông tin' },
        { id: 2, name: 'Đang xử lí' },
        { id: 3, name: 'Hoàn thành' },
        { id: 4, name: 'Từ chối' },
      ],
      selectedTicketType: -1,
      listTicketType: [{ id: -1, label: 'Chọn loại phiếu' }],
      limit: 10,
      onSaving: false,
      search: '',
      ticket: null,
      ticketUpdate: {
        id: null,
        status: -1,
        reason: null,
      },
      ticketType: {
        id: null,
        name: null,
        code: null,
        bot_id: -1,
        roles_id: [],
        userInGroupTele: [],
        functionAction: -1,
      },
      selectedUserInGroup: [],
      attachments: [],
      filteredBot: [{ id: -1, label: 'Chọn bot group thông báo' }],
      selectedRole: [],
      filterRole: [],
      listUser: [],
      titleModal: '',
      actionsUpSert: '',
      listStatusTicket: [
        { id: 0, name: 'Tắt' },
        { id: 1, name: 'Bật' },
      ],
    };
  },
  created() {
    this.fetchListTypeTicket();
    this.fetchBot();
    this.fetchRole();
    this.fetchFunctionAction();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Danh sách cấu hình loại phiếu',
        route: 'ticket-setting',
      },
    ]);
  },
  methods: {
    checkPermissions,
    pushItemUserInGroup(value) {
      this.ticketType.userInGroupTele.push(value);
    },
    removeItemUserInGroup(value) {
      this.ticketType.userInGroupTele = this.ticketType.userInGroupTele.filter(
        (item) => item.id !== value.id,
      );
    },
    createTicketType() {
      if (!this.ticketType.name) {
        makeToastFaile('Vui lòng nhập tên phiếu hỗ trợ');
        return;
      }

      if (!this.ticketType.bot_id || this.ticketType.bot_id === -1) {
        makeToastFaile('Bot group thông báo không hợp lệ');
        return;
      }

      if (!this.ticketType.roles_id || !this.ticketType.roles_id.length) {
        makeToastFaile('Nhóm quyền không hợp lệ');
        return;
      }
      if (this.ticketType.userInGroupTele.length) {
        const hasMissingValue = some(
          this.ticketType.userInGroupTele,
          (obj) => !obj.userIdOrNameTele,
        );
        if (hasMissingValue) {
          makeToastFaile('Tên hoặc ID của telegram không được để trống');
          return;
        }
      }
      this.ticketType.code = this.ticketType.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .replace(/ /g, '_');

      const payload = {
        name: this.ticketType.name,
        code: this.ticketType.code,
        bot_id: this.ticketType.bot_id,
        roles_id: this.ticketType.roles_id,
        userInGroupTele: this.ticketType.userInGroupTele,
      };
      if (this.ticketType.functionAction) {
        payload['functionAction'] = this.ticketType.functionAction;
      }
      ApiService.post('/v2/tickets/setting', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            this.fetchListTypeTicket();
            makeToastSuccess(message);
            this.hideModal();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          const { message } = response.data;
          makeToastFaile(message);
        });
    },

    updateTicketType() {
      if (!this.ticketType.name) {
        makeToastFaile('Vui lòng nhập tên phiếu hỗ trợ');
        return;
      }

      if (!this.ticketType.bot_id || this.ticketType.bot_id === -1) {
        makeToastFaile('Bot group thông báo không hợp lệ');
        return;
      }

      if (!this.ticketType.roles_id || !this.ticketType.roles_id.length) {
        makeToastFaile('Nhóm quyền không hợp lệ');
        return;
      }

      if (this.ticketType.userInGroupTele.length) {
        const hasMissingValue = some(
          this.ticketType.userInGroupTele,
          (obj) => !obj.userIdOrNameTele,
        );
        if (hasMissingValue) {
          makeToastFaile('Tên hoặc ID của telegram không được để trống');
          return;
        }
      }

      const payload = {
        id: this.ticketType.id,
        name: this.ticketType.name,
        bot_id: this.ticketType.bot_id,
        roles_id: this.ticketType.roles_id,
        status: this.ticketType.status,
        userInGroupTele: this.ticketType.userInGroupTele,
        functionAction: this.ticketType.functionAction,
      };

      ApiService.put('/v2/tickets/setting', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            this.fetchListTypeTicket();
            makeToastSuccess(message);
            this.hideModal();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          console.log({ response });
          const { message } = response.data;
          makeToastFaile(message);
        });
    },

    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    onSaveModal() {
      if (this.actionsUpSert === 'create') {
        this.createTicketType();
      } else {
        this.updateTicketType();
      }
    },
    handleGetListTicket() {
      this.fetchListTypeTicket();
    },
    fetchListTypeTicket: async function() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const payload = {
        params: {
          search: this.search.trim(),
          id: this.id,
        },
      };

      ApiService.query('/v2/tickets/type', payload).then((response) => {
        const dataResponse = response.data.data;
        this.items = [...dataResponse];
        this.totalItems = this.items.length;
      });
    },

    fetchBot: async function() {
      ApiService.query(`/chatbot-config`, {
        params: {
          pageSize: 99999,
          event: 2,
        },
      }).then((response) => {
        const bot = response.data.data.result;
        for (const item of bot) {
          this.filteredBot.push({
            id: item.id,
            label: item.name,
          });
        }
      });
    },
    fetchRole: async function() {
      ApiService.query(`/role`, {
        params: {
          limit: 99999,
          page: 1,
        },
      }).then((response) => {
        const role = response.data.data.list_role;
        for (const item of role) {
          this.filterRole.push({
            id: item.id,
            label: item.name,
          });
        }
      });
    },
    fetchUser: async function(value) {
      ApiService.query(`/user`, {
        params: {
          size: 99999,
          page: 1,
          roleName: value.label,
          roleId: value.id,
        },
      }).then((response) => {
        const data = response.data.data.list_user;
        for (const item of data) {
          const exists = this.listUser.some((user) => user.id === item.id);
          if (!exists) {
            this.listUser.push({
              id: item.id,
              label: item.employeeName,
            });
          }
        }
      });
    },

    fetchFunctionAction: async function() {
      ApiService.query(`/v2/tickets/function-action`).then((response) => {
        const data = response.data.data;
        for (const item of data) {
          this.listFunctionAction.push(item);
        }
      });
    },

    createItem() {
      this.titleModal = 'Thêm loại phiếu hỗ trợ';
      this.actionsUpSert = 'create';
      this.selectedUserInGroup = [];
      this.listUser = [];
      this.ticketType = {
        id: null,
        name: null,
        code: null,
        bot_id: -1,
        roles_id: [],
        userInGroupTele: [],
        functionAction: '',
      };
      this.showModal();
    },
    detailItem(item) {
      if (item) {
        this.actionsUpSert = 'update';
        this.titleModal = `Chi tiết cấu hình phiếu #${item.id}`;
        this.ticketType['id'] = item.id;
        this.ticketType['name'] = item.name;
        this.ticketType['code'] = item.code;
        this.ticketType['bot_id'] = JSON.parse(item.extra)['id'];
        this.ticketType['roles_id'] = Object.keys(JSON.parse(item.roles_id));
        this.ticketType['status'] = item.status;
        this.ticketType['functionAction'] = item.functionAction || '';

        if (this.ticketType['roles_id'].length) {
          const infoRole = Object.values(JSON.parse(item.roles_id));
          for (const item of infoRole) {
            const payload = {
              id: item.id,
              label: item.name,
            };
            this.fetchUser(payload).then();
          }
        }
        this.ticketType['userInGroupTele'] =
          JSON.parse(item.extra)['listUserInGroupTele'] ?? [];
        if (this.ticketType['userInGroupTele'].length) {
          this.ticketType['userInGroupTele'] = this.ticketType[
            'userInGroupTele'
          ].map((item) => {
            return {
              id: item.id,
              label: item.name,
              userIdOrNameTele: item.userIdOrNameTele,
            };
          });
          this.selectedUserInGroup = this.ticketType['userInGroupTele'];
        }
        this.showModal();
      }
    },
  },
};
</script>
